import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import { CardList, CardListItem, Card, CardLinkWrapper, CardMedia, CardContent, ContentHead, ContentEyebrow, CardTitle, ContentSub } from "../components/main/card";
import { color } from "../components/defaults/styles/theme";
import { css } from "@emotion/react";
import GeneralFooter from '../components/general_footer';
import { TitleHeader } from "../components/defaults/styles/elements";
import mq from "../components/defaults/styles/mediaquerys";
import ArchiveIcon from "../components/defaults/assets/svg/archive.svg";

const seo = {
  frontmatter: {
    title: "Archive",
  },
}

const ArchiveList = ({data}) => {
    const placeholder = '';
    return (
      <>
        <CardList
          css={css`
            padding-top: 0;
            margin-top: 4rem;
            ${mq[2]} {
              padding-top: 0;
              margin-top: 4rem;
            }
          `}
        >
          {data.map(({ node }, index) => (
            <CardListItem key={index}>
              <CardLinkWrapper cardLink={node.url} active="true">
                <Card>
                  <CardMedia center placeholder={placeholder}>{node.img}</CardMedia>
                  <CardContent>
                    <ContentHead>
                      <CardTitle>
                        <p>{node.title}</p>
                      </CardTitle>
                      <ContentEyebrow>
                        <p>{node.date}</p>
                      </ContentEyebrow>
                    </ContentHead>
                    <ContentSub>
                      <p>{node.location.place}</p>
                      {/* <p
                        css={css`
                          opacity: 0.3;
                        `}
                      >
                        {node.location.onsite ? "On Site" : "Virtual"}
                      </p> */}
                    </ContentSub>
                  </CardContent>
                </Card>
              </CardLinkWrapper>
            </CardListItem>
          ))}
          <CardListItem placeholder_item></CardListItem>
          <CardListItem placeholder_item></CardListItem>
          <CardListItem placeholder_item></CardListItem>
        </CardList>
      </>
    )
}


const Archive = ({ data }) => {
    return (
      <Layout seo={seo}>
        <TitleHeader
          title="Archive"
          subtitle="<p>So maybe you’ve attended our past events and feel like revisiting fond memories - or maybe you’ve only heard about EXPO but haven’t experienced it for yourself (at least not yet)? Well, you’ve come to the right place.</p><p>Below you can find some pretty pictures that will direct you to the websites of our past events. All you have to do is click on them to take a trip down memory lane.</p>"
          icon={{
            svg: <ArchiveIcon />,
            style: "fill"
          }}
        />
        <section
          css={css`
            background: ${color.main_light};
            position: relative;
            &:before {
              content: "";
              position: absolute;
              background: white;
              width: 100%;
              height: 1em;
              top: 0;
              left: 0;
            }
          `}
        >
          <ArchiveList data={data.allArchivedataJson.edges} />
        </section>
        <GeneralFooter theme={color.main_light} />
      </Layout>
    )   
}


export const query = graphql`{
  allArchivedataJson {
    edges {
      node {
        img {
          childImageSharp {
            gatsbyImageData(
              placeholder: TRACED_SVG
              transformOptions: {grayscale: false}
              layout: FULL_WIDTH
            )
          }
        }
        date
        title
        url
        location {
          onsite
          place
        }
      }
    }
  }
}
`



export default Archive;